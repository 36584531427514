<template>
  <div class="q-screen no-gutter items-center lg:w-4/5">
    <a @click="$router.push({ name: 'settings' })">
      <feather-icon
        icon="ChevronLeftIcon"
        class="cursor-pointer align-bottom"
      />Back to access & permissions
    </a>
    <vs-row class="mt-5">
      <vs-card v-if="user" class="p-5">
        <div class="main-user-container" v-if="user">
          <div class="user-avatar-container">
            <img
              class="profile-image"
              :src="defaultUserImage"
            />
          </div>
          <div class="user-information-container">
            <h3 class="mb-1 font-semibold">
              {{ user.fullName ? user.fullName : user.systemName }}
            </h3>
            <div v-if="user.userType === 'admin'" class="badge-container">
              <span class="badge flex items-center px-3 py-2">
                <vs-icon icon="person" class="pr-1 badge-icon"></vs-icon>
                <p class="text-sm">Admin</p></span
              >
            </div>
            <div v-if="user.userType !== 'admin'" class="badge-container user">
              <span class="badge flex items-center px-3 py-2">
                <vs-icon
                  icon="person_outline"
                  class="pr-1 badge-icon"
                ></vs-icon>
                <p class="text-sm">User</p></span
              >
            </div>
            <br />
            <div v-if="user.email">
              <span class="context-label">Email Address</span>
              <p class="mb-2">{{ user.email }}</p>
              <hr class="line-hr" />
            </div>
            <div v-if="user.description">
              <span class="context-label">Description</span>
              <p class="mb-2">{{ user.description }}</p>
              <hr class="line-hr" />
              <br />
            </div>
            <div v-if="user.phoneNumber">
              <span class="context-label">Mobile Phone Number</span>
              <p class="mb-2">{{ user.phoneNumber }}</p>
              <hr class="line-hr" />
              <br />
            </div>
            <div>
              <span class="context-label">User Group</span>
              <div class="flex flex-row">
                <div v-for="(item, index) in user.group" :key="item.id">
                  {{ index > 0 ? "," : "" }} {{ item.groupName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-card>

      <vx-card v-if="user.userType == 'apiUser'" class="mb-5">
        <h4 class="font-bold">Api Token</h4>
        <template>
          <vs-row>
            <vs-col vs-w="8" vs-xs="12">
              <div>
                <vs-input
                  class="w-full tokn"
                  label-placeholder="."
                  v-model="thirdpartyToken"
                  disabled
                />
              </div>
            </vs-col>
            <span class="pt-5 pl-3">
              <vs-tooltip text="Copy to clipboard">
                <vs-icon
                  icon="content_copy"
                  size="medium"
                  @click="copyThirdPartyTokenToClipboard"
                  class="clipIcon"
                />
                <span>&nbsp;</span>
              </vs-tooltip>
            </span>
            <vs-col vs-w="3" vs-xs="12" class="p-3">
              <div>
                <vs-button v-round @click="generateToken()">{{
                  thirdPartyToken ? "Refresh Api Token" : "Refresh Api Token"
                }}</vs-button>
              </div>
            </vs-col>
          </vs-row>
        </template>
      </vx-card>
    </vs-row>
    <div class="button-combo">
      <vs-button @click="editUser" class="ml-8 custom-button"
        >Edit user</vs-button
      >
      <vs-button
        @click="deleteButton"
        class="custom-button only-border-btn"
        v-round
        >Delete user</vs-button
      >
    </div>

    <vs-popup
      style="color: rgb(255, 255, 255)"
      background-color="rgba(255,255,255,.6)"
      title=""
      :background-color-popup="colorx"
      :active.sync="popupActive"
    >
      <div class="popup-header">
        <vs-icon
          icon="error_outline"
          size="3rem"
          class="mr-3 text-red"
        ></vs-icon>
        <b style="font-size: 2rem">Delete this user?</b>
      </div>
      <br />
      <p style="color: #828282" class="ml-16">
        Are you sure you want to permanently delete this user? You can't undo
        this action.
      </p>
      <br />
      <div class="error-message-container ml-16">
        <vs-icon icon="warning_amber" size="2rem" class="mr-3"></vs-icon>
        <div>
          <span>WARNING</span>
          <br />
          <p style="color: black">
            By deleting this user you are permanently removing their access to
            this platform.
          </p>
        </div>
      </div>
      <div class="button-combo pt-5">
        <vs-button
          @click="closeButton"
          v-if="user && user.userType !== 'admin'"
          class="custom-button ml-8 white-btn"
          >Cancel</vs-button
        >
        <vs-button @click="handleDelete" class="custom-button delete-user-btn"
          >Delete User</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      id: "",
      user: null,
      colorx: "#FFFFFF",
      popupActive: false,

      thirdpartyToken: "",
      defaultUserImage: require("@/assets/images/profile/default.png"),
    };
  },
  methods: {
    ...mapActions("partner", [
      "fetchStaffDetailsById",
      "deleteStaff",
      "resetThirdPartyToken",
    ]),
    async getuserById() {
      await this.fetchStaffDetailsById(this.id).then((result) => {
        this.user = result.data.data;
        this.thirdpartyToken = this.user.thirdPartyAcessToken;
        
      });
    },
    deleteButton() {
      this.popupActive = true;
    },
    closeButton() {
      this.popupActive = false;
    },
    async handleDelete() {
      this.$vs.loading();
      await this.deleteStaff(this.id)
        .then((result) => {
          this.$vs.loading.close();
          this.$router.push({
            name: "partner-users",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          
        });
    },
    editUser() {
      this.$router.push({
        name: "partner-users-edit",
        params: { id: this.id },
      });
    },
    async generateToken() {
      this.$vs.loading();
      await this.resetThirdPartyToken(this.id)
        .then((response) => {
          
          this.thirdpartyToken = response.data.data;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          
        });
    },
    copyThirdPartyTokenToClipboard() {
      navigator.clipboard.writeText(this.thirdpartyToken).then(() => {
        this.$vs.notify({
          title: "Copy to Clipboard",
          text: "Copied text to clipboard",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
      });
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getuserById();
  },
};
</script>